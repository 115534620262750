import {createTheme} from "@mui/material/styles";
import {csCZ as coreCsCz} from "@mui/material/locale"
import {csCZ} from "@mui/x-date-pickers-pro";

let theme = createTheme({
    palette: {
        primary: {
            light: '#57a1ff',
            main: '#2D83F4',
            dark: '#1766d7',
        },
        secondary: {
            light: '#dc57d4',
            main: '#c43ebb',
            dark: '#a2289a',
        },
        inactive: {
            light: '#e8e8e8',
            main: '#d0d0d0',
            dark: '#afafaf',
        }
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
}, coreCsCz, csCZ);

export default theme;
