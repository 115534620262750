import {BrowserRouter, Routes, Route} from "react-router-dom"
import Login from "./modules/Login/Login";
import Api from "./libs/Api/Api";
import Dashboard from "./modules/Dashboard/Dashboard";
import {ThemeProvider} from "@mui/material";
import theme from './config/theme'
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('8660d9c9fbf5a820484b6e3dfed1ef3dTz03ODE0MCxFPTE3MzA4OTk2MDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
    const api = new Api();

    if (api.user.logged) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path={'*'} element={<Dashboard api={api} />} />
                    </Routes>
                </BrowserRouter>
            </div>
            </ThemeProvider>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path={'*'} element={<Login api={api}/>} />
                    </Routes>
                </BrowserRouter>
            </div>
            </ThemeProvider>
        )
    }

}

export default App;
