import * as React from 'react'
import {Box, ImageList, Pagination, Skeleton} from "@mui/material";
import GridItem from "./GridItem";
import {useDispatch, useSelector} from "react-redux";
import {setLoaded, setPage} from "../../ReduxStores/select";
import dayjs from "dayjs";

let updateTimeout;

function Grid(props) {

    const {api, classId, dateRange, validated} = props;

    const loaded = useSelector(state=>state.select.loaded);
    const page = useSelector(state=>state.select.page);
    const [data, setData] = React.useState([]);
    const perPage = useSelector(state=>state.select.perPage)
    const numCols = useSelector(state=>state.select.numCols)
    const skeletonHeight = useSelector(state=>state.select.skeletonHeight)
    const [count, setCount] = React.useState(0);

    const dispatch = useDispatch();

    if (loaded === null) {
        updateTrainingData()
    }

    function updateTrainingData() {
        if (updateTimeout) clearTimeout(updateTimeout)
        updateTimeout = setTimeout(fetchData, 100);
    }

    function fetchData() {
        dispatch(setLoaded(false));
        api.getTrainingData(classId, dateRange[0],dayjs(dateRange[1]).add(1, 'day').format('YYYY-MM-DD'), validated, perPage, (page-1)).then(res => {
            dispatch(setLoaded(true));
            setData(res.data)
            setCount(res.count);
        })
    }
    console.log(perPage);

    return <><ImageList cols={Number(numCols)}>{loaded?data.map(item=>{
            return <GridItem {...props} key={'grid_item_'+item.id} item={item} />
        }
    ):[...Array(perPage)].map((e,i)=><Skeleton rad key={'skeleton_'+i} variant="rounded" height={skeletonHeight}/>)}</ImageList>
        {count>perPage?
            <Box sx={{display: 'flex'}} justifyContent="center" alignItems={"center"} minHeight={"100px"}>
                <Pagination
                    page={page}
                    onChange={(event,page)=>{
                        dispatch(setPage(page));
                        updateTrainingData()
                    }}
                    count={Math.floor(count/perPage)} color={'primary'} />
            </Box>:null}
    </>
}
export default Grid;
