import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from "../Grid/Grid";
import {LocalizationProvider, DateRangePicker, SingleInputDateRangeField} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from "dayjs";
import {cs} from "dayjs/locale/cs"
import {useDispatch, useSelector} from "react-redux";
import {
    setClassId,
    setDateRange,
    setNumCols,
    setPerPage,
    setValidatedOnly
} from "../../ReduxStores/select";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import TextField from "@mui/material/TextField";

dayjs.locale(cs);


const drawerWidth = 250;

function Dashboard(props) {
    const { window, api } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [table, setTable] = React.useState(null);
    const classId = useSelector(state=>state.select.classId)
    const dateRange = useSelector(state=>state.select.dateRange)
    const validated = useSelector(state=>state.select.validatedOnly)
    const numCols = useSelector(state=>state.select.numCols)
    const perPage = useSelector(state=>state.select.perPage)
    const dispatch = useDispatch();

    if (table === null) {
        setTable(false);
        api.getHashTable().then(res=>setTable(res.table));
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }


    const getGrid = () => {
        return <Grid {...props}
                     classId={classId}
                     table_hash={table}
                     dateRange={dateRange}
                     validated={validated}/>
    }

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <Typography sx={{ml:2, mt: 2}}>Class filter:</Typography>
            <List>
                <ListItem key={'all'} disablePadding>
                    <ListItemButton onClick={_=>dispatch(setClassId(null))} selected={classId===null}>
                        <ListItemText primary='all' />
                    </ListItemButton>
                </ListItem>
                {table?Object.keys(table).map((key) => (
                    <ListItem key={key} disablePadding>
                        <ListItemButton onClick={_=>dispatch(setClassId(key))} selected={classId===key}>
                            <ListItemText primary={key} />
                        </ListItemButton>
                    </ListItem>
                )):null}
            </List>
            <Divider />
            <Box sx={{ml: 2, mt: 4}}><LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography>Season select:</Typography>
                <DateRangePicker
                    sx={{width: 215}}
                    slots={{ field: SingleInputDateRangeField }}
                    value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
                    onChange={value=>dispatch(setDateRange(value))}
                    format={'YYYY-MM-DD'}
                    maxDate={dayjs()}
                />
            </LocalizationProvider></Box>
            <Box sx={{ml: 2, mt: 4}}>
                <FormControl>
                    <FormLabel id="show-types">Show: </FormLabel>
                    <RadioGroup
                        aria-labelledby="show-types"
                        value={validated}
                        name="radio-buttons-group"
                        onChange={event => dispatch(setValidatedOnly(event.target.value))}
                    >
                        <FormControlLabel value="all" control={<Radio />} label="All" />
                        <FormControlLabel value="validated" control={<Radio />} label="Vlidated" />
                        <FormControlLabel value="non-validated" control={<Radio />} label="Non validated" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={{ml: 2, mt: 4,  '& .MuiTextField-root': { m: 1 },}}>
                <Typography sx={{mb: 2}}>Image setting:</Typography>
                <TextField
                    label="Number of Columns"
                    id="num-cols"
                    value={numCols}
                    type={'number'}
                    size="small"
                    onChange={event=>dispatch(setNumCols(event.target.value))}
                />
                <TextField
                    label="Images per page"
                    id="image-per-page"
                    value={perPage}
                    size="small"
                    onChange={event=>dispatch(setPerPage(event.target.value))}
                />
            </Box>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        TORLIN Training Area
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {getGrid()}
            </Box>
        </Box>
    );
}

Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Dashboard;