import {createSlice} from '@reduxjs/toolkit';
import dayjs from "dayjs";

export const selectSlice = createSlice({
    name: 'response',
    initialState: {
        dateRange: [dayjs().subtract(1,'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        classId: null,
        loaded: null,
        validatedOnly: 'all',
        numCols: 2,
        perPage: 20,
        page: 1,
        skeletonHeight: 500,
    },
    reducers: {
        setDateRange (state, form) {
            state.dateRange = form.payload
            state.loaded = null
        },
        setClassId(state, response) {
            state.classId = response.payload
            state.loaded = null
        },
        setLoaded(state,payload) {
            state.loaded = payload.payload
        },
        setValidatedOnly(state,payload) {
            state.validatedOnly = payload.payload
            state.loaded = null
        },
        setNumCols(state,payload) {
            state.numCols = Number(payload.payload)
        },
        setPerPage(state,payload) {
            state.perPage = Number(payload.payload)
            state.loaded = null;
        },
        setPage(state,payload) {
            state.page = Number(payload.payload)
            state.loaded = null
        },
        setSkeletonHeight(state,height) {
            state.skeletonHeight = Number(height.payload+83)
        }
    }
})

export const { setSkeletonHeight, setPage, setDateRange, setClassId, setLoaded, setValidatedOnly, setNumCols, setPerPage } = selectSlice.actions;

export default selectSlice.reducer;
