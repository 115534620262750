import axios from "axios";
import User from "../User/User";
import ApiException from "./ApiException";

const API_URL = 'https://backend.trainarea.torlin.cz';

export default class Api {

    constructor() {
        this.interface = axios.create({withCredentials: true, baseURL: API_URL});
        this.user = new User();
        this.user.load();
    }


    controlResponse(response) {
        if (response.status === 200) {
            let data = response.data;
            if (data.status === 'error') {
                if (data.errno === 3003) {
                    this.user.logout();
                    window.location = '/';
                }
            }
            return data;
        } else {
            throw new ApiException(response.errno, response.message, response.responseCode)
        }
    }


    login(user) {
        this.user = user;
        return this.interface.post('acl/login', {user}).then(res => {return this.controlResponse(res)}).then(
            data => {
                if (data.status === 'ok') {
                    this.user.logged = true;
                    this.user.save();
                    window.location = '/';
                } else {
                    if (data.status === 3003) {
                        this.user.logout();
                        window.location = '/'
                    }
                    return false;
                }
            }
        )
    }

    logout() {
        this.interface.post('acl/logout').then(() => {
            this.user.logout();
            window.location = '/';
        })
    }

    getHashTable() {
        return this.interface.post('api/get-hash-table').then(res=>{return this.controlResponse(res)});
    }

    getTrainingData(classId, dateFrom, dateTill,validated, perPage,index) {
        return this.interface.post('api/get-training-data',{dateFrom: dateFrom, dateTill: dateTill, classId: classId,perPage: perPage, index:index, validated: validated})
            .then(res=>{return this.controlResponse(res)});
    }

    validateItem(rowId, newClasses, newCoordinates, newAreas, newDeletes) {
        return this.interface.post('api/validate-row',{rowHeadId: rowId, newClasses: newClasses, newCoordinates: newCoordinates, newAreas: newAreas, newDeletes: newDeletes}).then(res=>{return this.controlResponse(res)});
    }
}
