import {Component} from "react";
import React from 'react'
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
    ImageListItem,
    ImageListItemBar,
    ListItemIcon, ListItemText, Menu,
    MenuItem,
} from "@mui/material";
import {Circle, Layer, Line, Stage, Text} from 'react-konva'
import {ChangeCircle, Close, Create, Delete} from "@mui/icons-material";
import {connect, useSelector} from "react-redux";
import {setSkeletonHeight} from "../../ReduxStores/select";

class GridItem extends Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.state = {
            validated: this.props.item.validated,
            updated: false,
            newCoordinates: {},
            loaded: false,
            openedDeleteDialog: false,
            deleteAreas: [],
            prepareToDelete: null,
            newClasses: {},
            openContext: false,
            contextMenuPosition: {top: 0, left: 0},
            newAreas: []
        }
        this.handleUpdateArea = this.handleUpdateArea.bind(this);
        this.handleOpenDeleteDialog = this.handleOpenDeleteDialog.bind(this);
        this.handleChangeClass = this.handleChangeClass.bind(this);
        this.handleNewArea = this.handleNewArea.bind(this);

        this.myRef = React.createRef();
    }

    handleUpdateArea(area_id, coordinates) {
        if (typeof area_id === "string") {
            let areas = this.state.newAreas;
            for (let i=0;i<areas.length;i++) {
                if (areas[i].id === area_id) {
                    areas[i]['area'] = coordinates;
                    break;
                }
            }
            this.setState({newAreas: areas, updated: true})
        } else {
            let newCoordinates = this.state.newCoordinates;
            newCoordinates[area_id] = coordinates;
            this.setState({updated: true, newCoordinates: newCoordinates})
        }
    }

    handleOpenDeleteDialog(area_id) {
        // this.setState({openedDeleteDialog: true,prepareToDelete: area_id});
        if (typeof area_id === "string") {
            let areas = this.state.newAreas;
            let newAreas = [];
            for (let i=0;i<areas.length;i++) {
                if (areas[i].id !== area_id) {
                    newAreas.push(areas[i]);
                }
            }
            this.setState({newAreas: newAreas, updated: true, openedDeleteDialog: false, prepareToDelete: null})
        } else {
            let newDelete = this.state.deleteAreas;
            newDelete.push(area_id);
            this.setState({openedDeleteDialog: false, prepareToDelete: null, deleteAreas: newDelete, updated: true})
        }
    }

    handleChangeClass(area_id, newClass) {
        if (typeof area_id === "string") {
            let areas = this.state.newAreas;
            for (let i=0;i<areas.length;i++) {
                if (areas[i].id === area_id) {
                    areas[i]['class'] = newClass;
                    break;
                }
            }
            this.setState({newAreas: areas, updated: true})
        } else {
            let newClasses = this.state.newClasses;
            newClasses[area_id] = newClass;
            this.setState({newClasses: newClasses, updated: true})
        }
    }

    handleOpenImageContext(event) {
        event.cancelBubble = true;
        event.preventDefault();
        event.stopPropagation();
        let position = {top: event.clientY -2, left: event.clientX-4}
        this.setState({openContext: true, contextMenuPosition: position})
    }

    handleNewArea(event) {
        let position = this.myRef.current.getBoundingClientRect();
        let ratio = position.width/this.imageRef.current.naturalWidth;
        let posX = Math.max(0, Math.round((event.clientX - position.x)/ratio - 20));
        let posY = Math.max(0, Math.round((event.clientY - position.y)/ratio - 20));

        let newAreas = this.state.newAreas;
        let tmp = {
            id: 'new_'+newAreas.length,
            area: 'MULTIPOINT(('+posX+' '+posY+'),('+(posX+30)+' '+(posY+30)+'))',
            class: this.props.table_hash[Object.keys(this.props.table_hash)[0]],
            validated: 1
        }
        newAreas.push(tmp);
        this.setState({newAreas: newAreas})
    }

    render() {
        const item = this.props.item;
        let areas = [...item.areas];
        if (this.state.newAreas.length) {
            for (let i=0;i<this.state.newAreas.length;i++) {
                areas.push(this.state.newAreas[i]);
            }
        }
        if (this.state.deleteAreas.length) {
            let new_areas = [];
            for (let i=0;i<areas.length;i++) {
                if (this.state.deleteAreas.includes(areas[i].id)) continue;
                new_areas.push(areas[i]);
            }
            areas = new_areas;
        }
        if (Object.keys(this.state.newClasses).length) {
            let new_areas = [];
            for (let i=0;i<areas.length;i++) {
                let tmp = areas[i];
                if (this.state.newClasses[tmp.id] !== undefined) {
                    tmp['class'] = this.state.newClasses[tmp.id];
                }
                new_areas.push(tmp);
            }
            areas = new_areas;
        }
        let status = (this.state.updated) ? "updated" : (this.state.validated) ? "validated" : "detected";
        let title = <div>
            <Button variant={'contained'} onClick={() => {
                this.props.api.validateItem(item.id, this.state.newClasses, this.state.newCoordinates, this.state.newAreas, this.state.deleteAreas).then(res => {
                    if (res.status === 'ok') {
                        this.setState({newClass: '', newCoordinates: [], validated: true, updated: false})
                    } else {
                        alert(res.message);
                    }
                })
            }}>VALIDATE</Button>
        </div>

        return <div ref={this.myRef}>
            <ImageListItem
                className={status}
                onContextMenu={(event)=>{
                    this.handleOpenImageContext(event)
                }}
            >
                <img width={100} src={'data:image/jpg;base64, ' + item.image} alt={'image'} ref={this.imageRef} onLoad={() => {
                    this.setState({loaded: true})
                }}
                />
                {this.state.loaded && areas.length ?
                    <TorlinAreas table_hash={this.props.table_hash}
                                 areas={areas}
                                 image={this.imageRef}
                                 onChangeArea={this.handleUpdateArea}
                                 onDeleteArea={this.handleOpenDeleteDialog}
                                 onChangeClass={this.handleChangeClass}
                    /> : null}
                <ImageListItemBar
                    title={title}
                    position={'below'}/>
            </ImageListItem>
            <Dialog open={this.state.openedDeleteDialog}>
                <DialogTitle>Do you want to delete area?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you confirm, area will be deleted. You need to validate changes as well to make it permanent.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({openedDeleteDialog: false, prepareToDelete: null})}}>Keep</Button>
                    <Button variant={"contained"} color={'error'} onClick={()=>{
                        if (typeof this.state.prepareToDelete === "string") {
                            let areas = this.state.newAreas;
                            let newAreas = [];
                            for (let i=0;i<areas.length;i++) {
                                if (areas[i].id !== this.state.prepareToDelete) {
                                    newAreas.push(areas[i]);
                                }
                            }
                            this.setState({newAreas: newAreas, updated: true, openedDeleteDialog: false, prepareToDelete: null})
                        } else {
                            let newDelete = this.state.deleteAreas;
                            newDelete.push(this.state.prepareToDelete);
                            this.setState({openedDeleteDialog: false, prepareToDelete: null, deleteAreas: newDelete, updated: true})
                        }
                        }}>DELETE</Button>
                </DialogActions>
            </Dialog>
            <Menu open={this.state.openContext}
                  anchorReference={"anchorPosition"}
                  anchorPosition={this.state.contextMenuPosition}
                  onClose={()=>{this.setState({openContext: false})}}
            >
                <MenuItem onClick={
                    (event)=>{
                        this.handleNewArea(event);
                        this.setState({openContext: false})
                    }}>
                    <ListItemIcon>
                        <Create fontSize={'small'}/>
                    </ListItemIcon>
                    <ListItemText>New Area</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={()=>{
                    this.setState({openContext: false})
                }}>
                    <ListItemIcon>
                        <Close fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText>Cancel</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    }
}

class TorlinAreasTemplate extends Component {
    constructor(props) {
        super(props);
        let clientWidth = this.props.image.current.clientWidth;
        let clientHeight = this.props.image.current.clientHeight;
        this.state = {
            clientWidth: clientWidth,
            clientHeight: clientHeight,
            openContext: false,
            contextMenuPosition: {top: 0, left: 0},
            openChangeClass: false,
            contextAreaId: null,
        }
        this.setupConstants = this.setupConstants.bind(this);
        this.handleOpenContext = this.handleOpenContext.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.numCols !== this.props.numCols) {
            setTimeout(this.setupConstants, 50);
        }
    }

    setupConstants() {
        let clientWidth = this.props.image.current.clientWidth;
        let clientHeight = this.props.image.current.clientHeight;
        const { dispatch } = this.props;
        dispatch(setSkeletonHeight(clientHeight))
        this.setState({
            clientWidth: clientWidth,
            clientHeight: clientHeight,
        });
    }

    handleOpenContext(area_id, event) {
        event.cancelBubble = true;
        event.evt.preventDefault();
        event.evt.stopPropagation();
        let position = {top: event.evt.clientY -2, left: event.evt.clientX-4}
        this.setState({openContext: true, contextMenuPosition: position, contextAreaId: area_id})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setupConstants)
    }


    componentDidMount() {
        window.addEventListener('resize', this.setupConstants);
    }


    render() {
        return <div style={{position: 'absolute', zIndex: 1000}}>
            <Stage className={'torlin-area'} width={this.state.clientWidth} height={this.state.clientHeight}>
                {this.props.areas.map(item=>
                    <TorlinArea key={'area_id_'+item.id} {...this.props} area={item} handleContext={this.handleOpenContext} />)}
            </Stage>
            <Menu open={this.state.openContext}
                  anchorReference={"anchorPosition"}
                  anchorPosition={this.state.contextMenuPosition}
                  onClose={()=>{this.setState({openContext: false})}}
            >
                <MenuItem divider>
                    <ListItemIcon>
                        <ChangeCircle fontSize={'small'}/>
                    </ListItemIcon>
                    <ListItemText>Change Class</ListItemText>
                </MenuItem>
                {Object.keys(this.props.table_hash).map(classId=>{
                    return <MenuItem
                        onClick={()=>{
                            this.props.onChangeClass(this.state.contextAreaId, this.props.table_hash[classId]);
                            this.setState({openContext: false})
                        }}
                        key={'action_' + classId} dense={true}>{classId}</MenuItem>
                })}
                <Divider/>
                <MenuItem onClick={()=>{
                    this.props.onDeleteArea(this.state.contextAreaId)
                    this.setState({openContext: false})
                }}>
                    <ListItemIcon>
                        <Delete fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText>Delete Area</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={()=>{
                    this.setState({openContext: false})
                }}>
                    <ListItemIcon>
                        <Close fontSize={"small"} />
                    </ListItemIcon>
                    <ListItemText>Cancel</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    }
}

class TorlinAreaTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prepared: false,
            clientWidth: 0,
            clientHeight: 0,
            naturalWidth: 0,
            ratio: 1,
            startX: 0,
            startY: 0,
            realCoordinates: [],
            coordinates: [],
        }
        this.colors = {
            negative: '#ff8608',
            person: '#24ff00',
            baguette: '#73e573',
            bottle: '#f300ca',
            cup: '#f7ff00',
            car: '#00eaff',
            cone: '#9900ff',
            forklift: '#657eff',
            palette: '#009f00',
            unknown: '#FF0000',
        }
        this.setupConstants = this.setupConstants.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.numCols !== this.props.numCols) {
            setTimeout(this.setupConstants, 50);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.setupConstants);

        const regExp = RegExp('[0-9]+', 'g');
        let area = this.props.area.area;
        let coordinates = [];
        let tmp;
        while (tmp = regExp.exec(area)) {
            coordinates.push(tmp[0])
        }

        this.setState({realCoordinates: coordinates}, () => {
            this.setupConstants();
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setupConstants)
    }


    setupConstants() {
        let clientWidth = this.props.image.current.clientWidth;
        let clientHeight = this.props.image.current.clientHeight;
        let naturalWidth = this.props.image.current.naturalWidth;
        let ratio = clientWidth / naturalWidth;
        let coordinates = [];
        for (let index in this.state.realCoordinates) {
            coordinates.push(this.state.realCoordinates[index] * ratio);
        }
        this.setState({
            clientWidth: clientWidth,
            clientHeight: clientHeight,
            naturalWidth: naturalWidth,
            ratio: ratio,
            coordinates: coordinates,
            prepared: true,
            openContext: false,
        });
    }

    handleChangeCoordinates(x, y, point) {
        let realX = x / this.state.ratio;
        let realY = y / this.state.ratio;
        let coordinates = this.state.coordinates;
        let realCoordinates = this.state.realCoordinates;

        if (point === 0) {
            coordinates[0] = x;
            coordinates[1] = y;
            realCoordinates[0] = realX;
            realCoordinates[1] = realY;
        } else if (point === 1) {
            coordinates[2] = x;
            coordinates[1] = y;
            realCoordinates[2] = realX;
            realCoordinates[1] = realY;
        } else if (point === 2) {
            coordinates[0] = x;
            coordinates[3] = y;
            realCoordinates[0] = realX;
            realCoordinates[3] = realY;
        } else if (point === 3) {
            coordinates[2] = x;
            coordinates[3] = y;
            realCoordinates[2] = realX;
            realCoordinates[3] = realY;
        }

        this.setState({coordinates: coordinates, realCoordinates: realCoordinates});
        this.props.onChangeArea(this.props.area.id, realCoordinates);
    }

    render() {
        const swapped = Object.entries(this.props.table_hash).map(
            ([key, value]) => [value, key]
        )
        const labels = Object.fromEntries(swapped);
        const label = labels[this.props.area.class];
        const color = this.colors[label]??'#00eaff';

        if (this.state.prepared === false) {
            return null;
        }
        let points = [
            this.state.coordinates[0], this.state.coordinates[1],
            this.state.coordinates[2], this.state.coordinates[1],
            this.state.coordinates[2], this.state.coordinates[3],
            this.state.coordinates[0], this.state.coordinates[3],
            this.state.coordinates[0], this.state.coordinates[1]];

        return <Layer>
            <Line x={0} y={0}
                  points={points}
                  stroke={color}
                  strokeWidth={2}
            />
            <Text
                fontSize={14}
                x={this.state.coordinates[0] + 5}
                y={this.state.coordinates[1]}
                text={label}
                fill={color}
                wrap="char"
                align="left"/>
            <Circle draggable x={this.state.coordinates[0]} y={this.state.coordinates[1]} radius={5}
                    fill={color}
                    onDragMove={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 0)
                    }}
                    onContextMenu={(event)=>{
                        this.props.handleContext(this.props.area.id, event)
                    }}
                    onDragEnd={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 0)
                    }}/>
            <Circle draggable x={this.state.coordinates[2]} y={this.state.coordinates[1]} radius={5}
                    fill={color}
                    onDragMove={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 1)
                    }}
                    onContextMenu={(event)=>{
                        this.props.handleContext(this.props.area.id, event)
                    }}
                    onDragEnd={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 1)
                    }}/>
            <Circle draggable x={this.state.coordinates[0]} y={this.state.coordinates[3]} radius={5}
                    fill={color}
                    onDragMove={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 2)
                    }}
                    onContextMenu={(event)=>{
                        this.props.handleContext(this.props.area.id, event)
                    }}
                    onDragEnd={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 2)
                    }}/>
            <Circle draggable x={this.state.coordinates[2]} y={this.state.coordinates[3]} radius={5}
                    fill={color}
                    onDragMove={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 3)
                    }}
                    onContextMenu={(event)=>{
                        this.props.handleContext(this.props.area.id, event)
                    }}
                    onDragEnd={(elm) => {
                        this.handleChangeCoordinates(elm.target.attrs.x, elm.target.attrs.y, 3)
                    }}/>
        </Layer>
    }
}
function mapStateToProps(state) {
    const numCols = state.select.numCols;
    return {
        numCols,
    };
}
const TorlinArea = connect(mapStateToProps)(TorlinAreaTemplate);
const TorlinAreas = connect(mapStateToProps)(TorlinAreasTemplate);


export default GridItem;
